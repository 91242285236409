<template>
    <form v-if="show" id="contact-form" action="#" @submit="sendForm" class="contact__form">
        <div class="form-msg" :class="{ active: sent }">
            <img title="éxito" alt="éxito" class="form-msg__icon" src="images/home/contact/success-icon.svg" />
            <h3 class="form-msg__title">Mensaje enviado</h3>
            <p class="form-msg__text">En breve, nos estaremos comunicando con vos.</p>
            <button class="form-msg__button" @click.prevent="sent = false">
                Volver
            </button>
        </div>
        <div class="form-msg" :class="{ active: sentError }">
            <img title="" alt="" class="form-msg__icon" src="images/home/contact/success-icon.svg" />
            <h3 class="form-msg__title">Se produjo un error.</h3>
            <p class="form-msg__text">
                Tu mensaje no ha podido ser enviado. Volvé a intentar por favor.
            </p>
            <button class="form-msg__button" @click.prevent="clearForm">
                Volver a enviar
            </button>
        </div>

        <input type=hidden id="00D7i0000003fnK" name="oid" value="00D7i0000003fnK">
        <input type="hidden" id="leadsource" name="leadsource" value="Web" />
        <input type=hidden id="recordType" name="recordType" value="0124x000000hccd">

        <div class="form__field">
            <input class="field__input" name="company-name" id="company-name" placeholder="Nombre de la empresa (*)"
                v-model="form.companyName" @blur="v$.form.companyName.$touch()" maxlength="100" />
            <p v-if="v$.form.companyName.validText.$invalid" class="field-input__error-msg"
                :class="{ active: v$.form.companyName.$error }">
                No permite caracteres especiales.
            </p>
            <p v-if="v$.form.companyName.required.$invalid" class="field-input__error-msg"
                :class="{ active: v$.form.companyName.$error }">
                El campo es requerido.
            </p>

            <label for="company" class="field__label">Nombre de la empresa</label>
        </div>
        <div class="form__field">
            <input class="field__input" name="YERCUIT__c" id="YERCUIT__c" v-model="form.cuit" @blur="v$.form.cuit.$touch()"
                v-maska="'###########'" placeholder="CUIT XXXXXXXXXXX (*)" />
            <p class="field-input__error-msg" :class="{ active: v$.form.cuit.$error }">
                El cuit que ingresaste no es válido, por favor revisá el formato
                XX-XXXXXXXX-X.
            </p>
            <label for="cuit" class="field__label">CUIT</label>
        </div>
        <div class="form__field">
            <input class="field__input" name="first_name" id="first_name" v-model="form.contactName"
                @blur="v$.form.contactName.$touch()" maxlength="100" placeholder="Nombre del responsable (*)" />
            <p class="field-input__error-msg" :class="{ active: v$.form.contactName.$error }">
                Por favor, completá el nombre del responsable. Lo necesitamos para
                contactarte.
            </p>
            <label for="first_name" class="field__label">Nombre del responsable</label>
        </div>
        <div class="form__field">
            <input class="field__input" name="last_name" id="last_name" v-model="form.contactLast"
                @blur="v$.form.contactLast.$touch()" maxlength="100" placeholder="Apellido del responsable (*)" />
            <p class="field-input__error-msg" :class="{ active: v$.form.contactLast.$error }">
                Por favor, completá el apellido del responsable. Lo necesitamos para
                contactarte.
            </p>
            <label for="first_name" class="field__label">Apellido del responsable</label>
        </div>
        <div class="form__field">
            <input class="field__input" name="title" id="title" v-model="form.contactRole"
                @blur="v$.form.contactRole.$touch()" maxlength="100" placeholder="Puesto del Responsable" />
            <label for="contact-role" class="field__label">Puesto del Responsable</label>
        </div>
        <div class="form__field">
            <input class="field__input" name="email" id="email" type="email" v-model="form.contactEmail"
                @blur="v$.form.contactEmail.$touch()" maxlength="100" placeholder="Email mail@mail.com (*)" />
            <p class="field-input__error-msg" :class="{ active: v$.form.contactEmail.$error }">
                Por favor, ingresá un e-mail. Lo necesitamos para contactarte.
            </p>
            <label for="contact-email" class="field__label">Email</label>
        </div>
        <div class="form__field">
            <input class="field__input" name="phone" id="phone" type="tel" v-model="form.contactPhone"
                @blur="v$.form.contactPhone.$touch()" maxlength="22" placeholder="Teléfono 54 XXX XXXX XXXX (*)" />
            <p class="field-input__error-msg" :class="{ active: v$.form.contactPhone.$error }">
                Por favor, completá el teléfono. Lo necesitamos para contactarte.
            </p>
            <label for="contact-phone" class="field__label">Teléfono</label>
        </div>
        <div class="form__field">
            <input class="field__input" id="MobilePhone" name="MobilePhone" type="tel" v-model="form.contactMobile"
                @blur="v$.form.contactMobile.$touch()" maxlength="22" placeholder="Celular 54 XXX XXXX XXXX (*)" />
            <p class="field-input__error-msg" :class="{ active: v$.form.contactMobile.$error }">
                Por favor, completá el celular. Lo necesitamos para contactarte.
            </p>
            <label for="contact-phone" class="field__label">Celular</label>
        </div>
        <div class="form__field">
            <input class="field__input" id="YERFleetSize__c" name="YERFleetSize__c" v-model="form.fleetQuantity"
                @blur="v$.form.fleetQuantity.$touch()" v-maska="'#####'" maxlength="5"
                placeholder="Cantidad de unidades de flota (*)" />
            <p class="field-input__error-msg" :class="{ active: v$.form.fleetQuantity.$error }">
                Por favor, completá la cantidad de unidades para poder ofrecerte un plan
                adecuado a lo que necesitás.
            </p>
            <label for="fleet-quantity" class="field__label">Cantidad de unidades de flota</label>
        </div>
        <div class="form__field ">
            <select class="field__input" id="YERPlan__c" name="YERPlan__c" v-model="form.selectedPlan"
                @blur="v$.form.selectedPlan.$touch()">
                <option value="" disabled selected>
                    ¿Qué plan o servicio te interesa?
                </option>
				<option value="Plan RT Clasico">Plan RT Clásico</option>
                <option value="Plan RT Optimo">Plan RT Optimo</option>
                <option value="Plan RT Avanzado">Plan RT Avanzado</option>
				<option value="Servicio Filler">Servicio Filler (reparto capilar directo a unidades de flotas)</option>
                <option value="Servicio YPF UNICCO">Servicio YPF UNICCO (solución para patios de carga)</option>
            </select>
            <label for="selected-plan" class="field__label">Planes</label>
        </div>
        <div class="form__field form__field--textarea">
            <textarea class="field__input" name="YERConsulta__c" id="YERConsulta__c" placeholder="Consulta o Mensaje"
                v-model="form.comments" @blur="v$.form.comments.$touch()" maxlength="512"></textarea>
            <p v-if="v$.form.comments.validText.$invalid" class="field-input__error-msg"
                :class="{ active: v$.form.comments.$error }">
                No permite caracteres especiales.
            </p>
            <label for="comments" class="field__label">Consulta</label>
        </div>
        <div class="form__switch-container">
            <label class="form__switch-title" for="hasYpfRuta">¿Tenes YPF Ruta?</label>
            <div class="form__switch" @click="toggle" :class="{ active: v$.form.hasYpfRuta.$model == 'true' }">
                <input class="form__switch-input" type="button" name="YERIsClient__c" id="YERIsClient__c" />
            </div>
        </div>
        <div class="form__submit-container">
            <div style="margin-bottom: 1.7rem">
                <vue-recaptcha v-show="showRecaptcha" :siteKey="siteKey" @verify="recaptchaVerified"
                    @expire="recaptchaExpired" @fail="recaptchaFailed" ref="vueRecaptcha">
                </vue-recaptcha>
                <p class="field-input__error-msg" :class="{ active: v$.recaptcha.$error }">
                    Aceptá el captcha por favor
                </p>
            </div>
            <button class="button button--primary form__submit-button" type="submit" :disabled="loading">
                {{ btnMsg }}
            </button>
        </div>
    </form>
</template>reset

<script>

import axios from 'axios';
import vueRecaptcha from 'vue3-recaptcha2';
import useVuelidate from '@vuelidate/core';
import { helpers } from 'vuelidate/lib/validators'

import {
    required,
    numeric,
    email,
    minValue
} from '@vuelidate/validators';
import { maska } from 'maska';

const validateCUIT = function (cuit) {
    const cuitRegex = /[0-9]{11}/g;
    if (!cuitRegex.test(cuit)) {
        return false
    } else {
        let count = 0;
        let digits = cuit.split('');
        let digit = parseInt(digits.pop());

        for (let i = 0; i < digits.length; i++) {
            count += digits[9 - i] * (2 + (i % 6));
        }

        let verif = 11 - (count % 11);
        if (verif === 11) {
            verif = 0;
        } else if (verif === 10) {
            verif = 9;
        }

        return digit === verif;
    }
}

const validText = helpers.regex('validText', /^[a-z0-9_ ]*$/i)

const vphone = function (contactPhone) {
    const phoneRegex = /(\+54)?[\s-]?([0-9]{1,2})?[\s-]?([0-9]{1,4})?[\s-]?[0-9]{4}[\s-]?[0-9]{4}/g;
    if (phoneRegex.test(contactPhone)) {
        return true;
    } else {
        return false;
    }
}

export default {
    name: 'SectionContact',
    directives: { maska },
    components: {
        vueRecaptcha,
    },
    async mounted() {
        const appsettings = await fetch('__yforms/appsettings.json').then(
            (response) => response.json()
        );
        this.webApiURL = appsettings.webApiURL;
        this.siteKey = appsettings.siteKey;
        this.show = true;
        this.showRecaptcha = true;
        this.client_id = appsettings.client_id;
        this.client_secret = appsettings.client_secret;
        this.urlToken = appsettings.urlToken;
        this.urlSalesForce = appsettings.urlSalesForce;
        console.log(this.urlToken);
    },
    data() {
        return {
            show: false,
            webApiURL: '',
            siteKey: '',
            showRecaptcha: false,
            client_id: '',
            client_secret: '',
            btnMsg: 'Enviar',
            sent: false,
            sentError: false,
            loading: false,
            recaptcha: '',
            form: {
                companyName: '',
                cuit: '',
                contactName: '',
                contactLast: '',
                contactRole: '',
                contactEmail: '',
                contactPhone: '',
                contactMobile: '',
                fleetQuantity: '',
                hasYpfRuta: 'false',
                selectedPlan: '',
                comments: '',
            },
        };
    },
    validations() {
        return {
            recaptcha: {
                required: false,
            },
            form: {
                companyName: {
                    validText,
                    required
                },
                cuit: {
                    required,
                    validateCUIT,
                },
                contactName: {
                    required,
                },
                contactLast: {
                    required,
                },
                contactRole: {
                    required,
                },
                contactEmail: {
                    required,
                    email,
                },
                contactPhone: {
                    required,
                    numeric,
                    vphone,
                },
                contactMobile: {
                    required,
                    numeric,
                    vphone,

                },
                fleetQuantity: {
                    required,
                    numeric,
                    minValue: minValue(1),
                },
                hasYpfRuta: {},
                selectedPlan: {},
                comments: {
                    validText
                },
            },
        };
    },
    setup() {
        return { v$: useVuelidate() };
    },
    methods: {
        recaptchaVerified(response) {
            this.recaptcha = response;
        },
        recaptchaExpired() {
            this.recaptcha = '';
            this.$refs.vueRecaptcha.reset();
        },
        recaptchaFailed() {
            this.recaptcha = '';
        },
        clearForm() {
            let self = this;
            self.form = {
                companyName: '',
                cuit: '',
                contactName: '',
                contactLast: '',
                contactRole: '',
                contactEmail: '',
                contactPhone: '',
                contactMobile: '',
                fleetQuantity: '',
                hasYpfRuta: 'No',
                selectedPlan: '',
                comments: '',
            };
            !self.v$.$reset();
            self.scrolling('success-icon');
            self.loading = false;
            self.sentError = false;
            self.btnMsg = 'Enviar';
        },
        async getTokenSF() {
            let result = '';
            try {
                const params = {
                    "client_id": this.client_id,
                    "client_secret": this.client_secret
                };
                const token = await axios.post(this.urlToken, params);
                result = token.data.access_token;
            } catch (e) {
                result = e;
            }
            return result;
        },
        async sendForm(e) {
            e.preventDefault();
            let self = this;
            self.v$.$touch();
            //  let isToken = await this.getTokenSF();
            if (!self.v$.$invalid) {
                self.loading = true;
                self.btnMsg = 'Enviando...';
                self.sentError = false;
                //      console.log(this.urlSalesForce);
                // const params = {
                //  "client_id": this.client_id,
                //  "client_secret": this.client_secret,
                //  "data": {
                //      leadsource: 'Web', recordTypeId: '0124x000000hccd', 'YERCUIT__c': `${self.form.cuit}`, 
                //      Company: `${self.form.companyName}`, Title: `${self.form.contactRole}`, FirstName: `${self.form.contactName}`, 
                //      LastName: `${self.form.contactLast}`, Email: `${self.form.contactEmail}`, Phone: `${self.form.contactPhone}`, 
                //      MobilePhone: `${self.form.contactMobile}`, YERFleetSize__c: `${self.form.fleetQuantity}`, 
                //      YERPlan__c: `${self.form.selectedPlan}`, YERConsulta__c: `${self.form.comments}`, 
                //      YERIsClient__c: `${self.form.hasYpfRuta}` 
                //  }
                // };
                //const token = await axios.post({this.urlToken, params)
                axios
                    .post(
                        this.urlToken,
                        {
                            client_id: this.client_id,
                            client_secret: this.client_secret,
                            data: { leadsource: 'Web', recordTypeId: '0124x000000hccd', 'YERCUIT__c': `${self.form.cuit}`, Company: `${self.form.companyName}`, Title: `${self.form.contactRole}`, FirstName: `${self.form.contactName}`, LastName: `${self.form.contactLast}`, Email: `${self.form.contactEmail}`, Phone: `${self.form.contactPhone}`, MobilePhone: `${self.form.contactMobile}`, YERFleetSize__c: `${self.form.fleetQuantity}`, YERPlan__c: `${self.form.selectedPlan}`, YERConsulta__c: `${self.form.comments}`, YERIsClient__c: `${self.form.hasYpfRuta}` }
                        },
                        {
                            'Content-Type': 'application/json'
                        }
                    ).then(function (response) {
                        if (response.data.success) {
                            self.sent = true;
                            self.form = {
                                companyName: '',
                                cuit: '',
                                contactName: '',
                                contactLast: '',
                                contactRole: '',
                                contactEmail: '',
                                contactPhone: '',
                                contactMobile: '',
                                fleetQuantity: '',
                                hasYpfRuta: 'No',
                                selectedPlan: '',
                                comments: '',
                            };
                            !self.v$.$reset();
                            self.scrolling('success-icon');
                            self.btnMsg = 'Enviar';
                        } else {
                            self.sentError = true;
                            self.loading = false;
                            self.btnMsg = 'Enviar';
                            self.scrolling('error-icon');
                        }
                    })
                    .catch(function (error) {
                        console.log(error);
                        console.error(error);
                        self.sentError = true;
                        self.scrolling('error-icon');
                    });
            } else {
                for (let key in Object.keys(self.v$.form)) {
                    const input = Object.keys(self.v$.form)[key];
                    if (self.v$.form[input].$error) {
                        self.scrolling(input);
                        break;
                    }
                }
            }
        },



        // async sendForm(e) {
        //  e.preventDefault();
        //  let self = this;
        //  self.v$.$touch();
        //  let isToken = await this.getTokenSF();
        //  if (!self.v$.$invalid) {
        //      self.loading = true;
        //      self.btnMsg = 'Enviando...';
        //      self.sentError = false;
        //      console.log(this.urlSalesForce);
        //      axios({
        //          method: 'post',
        //          url: this.urlSalesForce,
        //          headers: { 'Authorization': `Bearer ${isToken}`, 'Content-Type': 'application/json' },
        //          data: { leadsource: 'Web', recordTypeId: '0124x000000hccd', 'YERCUIT__c': `${self.form.cuit}`, Company: `${self.form.companyName}`, Title: `${self.form.contactRole}`, FirstName: `${self.form.contactName}`, LastName: `${self.form.contactLast}`, Email: `${self.form.contactEmail}`, Phone: `${self.form.contactPhone}`, MobilePhone: `${self.form.contactMobile}`, YERFleetSize__c: `${self.form.fleetQuantity}`, YERPlan__c: `${self.form.selectedPlan}`, YERConsulta__c: `${self.form.comments}`, YERIsClient__c: `${self.form.hasYpfRuta}` }
        //      }).then(function (response) {
        //          if (response.data.success) {
        //              self.sent = true;
        //              self.form = {
        //                  companyName: '',
        //                  cuit: '',
        //                  contactName: '',
        //                  contactLast: '',
        //                  contactRole: '',
        //                  contactEmail: '',
        //                  contactPhone: '',
        //                  contactMobile: '',
        //                  fleetQuantity: '',
        //                  hasYpfRuta: 'No',
        //                  selectedPlan: '',
        //                  comments: '',
        //              };
        //              !self.v$.$reset();
        //              self.scrolling('success-icon');
        //              self.btnMsg = 'Enviar';
        //          } else {
        //              self.sentError = true;
        //              self.loading = false;
        //              self.btnMsg = 'Enviar';
        //              self.scrolling('error-icon');
        //          }
        //      })
        //          .catch(function (error) {
        //              console.log(error);
        //              console.error(error);
        //              self.sentError = true;
        //              self.scrolling('error-icon');
        //          });
        //  } else {
        //      for (let key in Object.keys(self.v$.form)) {
        //          const input = Object.keys(self.v$.form)[key];
        //          if (self.v$.form[input].$error) {
        //              self.scrolling(input);
        //              break;
        //          }
        //      }
        //  }
        // },
        toggle() {
            if (this.form.hasYpfRuta == 'false' || this.form.hasYpfRuta == '') {
                this.form.hasYpfRuta = 'true';
            } else {
                this.form.hasYpfRuta = 'false';
            }
        },
        scrolling(name) {
            let elm = document.getElementById(name);
            if (elm != null && typeof elm.scrollIntoView != 'undefined') {
                elm.scrollIntoView();
            }
            window.scroll(0, window.scrollY - 150);
        },
        isJsonString(str) {
            try {
                JSON.parse(str);
            } catch (e) {
                return false;
            }
            return true;
        },
    },
};
</script>

<style></style>